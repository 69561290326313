import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Guides & Tools",
  "sort": 1,
  "title": "Prefilled input fields",
  "subtitle": "How to specify input that the user would otherwise be queried for"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In some login flows, user-specific data other than actual login details are needed to complete the authentication. `}</p>
    <p>{`Examples include the CPR number in Denmark for personal MitID and SSN ("personnummer") in Sweden for BankID on another device.`}</p>
    <p>{`By default the user is prompted for this data when needed, but if you would like to avoid these dialogs, you can specify the values up-front in the request for authentication, via the `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` query parameter. This works for both OpenID Connect and WS-Federation.`}</p>
    <p>{`The actual support for controlling the input data varies by eID method:`}</p>
    <ol>
      <li parentName="ol">{`Danish MitID: Use `}<inlineCode parentName="li">{`login_hint=sub:<CPR>`}</inlineCode>{` where `}<inlineCode parentName="li">{`<CPR>`}</inlineCode>{` has the format `}<inlineCode parentName="li">{`DDMMYYXXXX`}</inlineCode></li>
      <li parentName="ol">{`Danish MitID Erhverv: Use `}<inlineCode parentName="li">{`login_hint=vatid:DK<CVR>`}</inlineCode>{`, `}<a parentName="li" {...{
          "href": "/verify/e-ids/danish-mitid-erhverv/#prefill-vat-id-cvr-number"
        }}>{`additional details`}</a></li>
      <li parentName="ol">{`Swedish BankID: Use `}<inlineCode parentName="li">{`login_hint=sub:<SSN>`}</inlineCode>{` where `}<inlineCode parentName="li">{`<SSN>`}</inlineCode>{` has the format `}<inlineCode parentName="li">{`YYYYMMDDXXXX`}</inlineCode></li>
      <li parentName="ol">{`Norwegian BankID Mobile: Use `}<inlineCode parentName="li">{`login_hint=BIM:<PHONENUMBER>:<BIRTHDATE>`}</inlineCode>{` where `}<inlineCode parentName="li">{`<PHONENUMBER>`}</inlineCode>{` has 8 digits and `}<inlineCode parentName="li">{`<BIRTHDATE>`}</inlineCode>{` has the format `}<inlineCode parentName="li">{`DDMMYY`}</inlineCode></li>
      <li parentName="ol">{`Norwegian BankID Kodebrikke: Use `}<inlineCode parentName="li">{`login_hint=BID:<SSN>`}</inlineCode>{` where `}<inlineCode parentName="li">{`<SSN>`}</inlineCode>{` has the format `}<inlineCode parentName="li">{`DDMMYYXXXXX`}</inlineCode></li>
      <li parentName="ol">{`Norwegian BankID Biometrics: Use `}<inlineCode parentName="li">{`login_hint=BIS:<SSN>`}</inlineCode>{` where `}<inlineCode parentName="li">{`<SSN>`}</inlineCode>{` has the format `}<inlineCode parentName="li">{`DDMMYYXXXXX`}</inlineCode></li>
    </ol>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p>{`Note that you should not include the angle brackets, so for example a Danish MitID prefilled CPR
would be passed like this: `}<inlineCode parentName="p">{`login_hint=sub:2201891234`}</inlineCode>{`.`}</p>
    </Highlight>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      